#button_payment {
    border: none;
    background-color: #8790c4;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    margin-left: 10px;
    border-radius: 5px;
}
#button_payment:hover{
    background-color: #64687e;
}
#money-form{
    border-radius: 8px;
    border: none;
    outline: none;
}
#money-form:focus{
    border: 2px solid #8790c4;
}