.App {
    text-align: center;
  }
  
  .App-header {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  body {
    background-color: #22293c;
    font-family: 'Poppins', sans-serif;
    color: white;
  }  
  main {
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    border-radius: 5px;
    background-color: #293146;
  }
  table tr:hover {
    background-color: #171e32;
  }
  table th{
    color:  #8790c4;
    background-color: #293146;
    text-align: center;
  }
  table tr{
    text-align: center;
  }
  table td, table th{
    border: 1px solid #6c717a;
  }

  