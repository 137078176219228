h1 {
  color: white;
}

p {
  color: white;
}

.Students {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* Left side */
.left {
  height: 100vh;
  position: sticky !important;
  z-index: 99;
  width: 15%;
  background-color: #293146;
}

/* Right side */

.right {
  width: 85%;
  background-color: #191d33;
  height: 100vh;
}

.top {
  width: 100%;
  height: 80px;
  background-color: brown;
}

.right .container {
  width: 85%;
  margin: auto;
}

.student_map {
  width: 90%;
  margin: auto;
  padding-top: 40px;
  display: grid;
  gap: 75px;
  grid-template-columns: repeat(4, 1fr);
}

.student_card {
  width: 100%;
  height: 300px;
  border-radius: 30px;
  background: #212121;
  box-shadow: 5px 5px 10px rgb(25, 25, 25), -5px -5px 10px rgb(60, 60, 60);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
