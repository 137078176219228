
#header-left:hover #header-left-icon{
	background-color: #3F8DBF;
}
#header-left:hover #header-left-text{
	background-color: #599BC8;
}
#header-left-icon{
	float: left;
	height: 50px;
	width: 50px;
	text-align: center;
	background-color: #35759F;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
#header-left-icon a {
	font-size: 25px;
}

#header-left-text{
	float: left;
	height: 50px;
		-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
#header-left-text a {
	padding-left: 20px;
	padding-right: 20px;
}
#header-right{
	float: right;
	height: 50px;
}
#header-right:hover #header-right-icon{
	background-color: #3F8DBF;
	}
#header-right:hover #header-right-text{
	background-color: #599BC8;
	}
#header-right-icon{
	float: right;
	height: 50px;
	width: 50px;
	text-align: center;
	background-color: #35759F;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
#header-right-text{
	float: right;
	height: 50px;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
#header-right-text a {
	padding-right: 20px;
	padding-left: 20px;
}
#gallery{
	float: left;
	width: 100%;
}
#gallery-header{
	height: auto;
	width: 100%;
	float: left;
}
#gallery-header-center{
	height: auto;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
#gallery-header-center-left{
	float: left;
	height: 35px;
	line-height: 35px;
	margin-bottom: 20px;
}
#gallery-header-center-left-icon{
	float: left;
	height: 35px;
	width: 35px;
	background-color: rgba(63,141,191,1);
	color: rgba(255,255,255,1);
	text-align: center;
	font-size: 20px;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
#gallery-header-center-left-icon:hover {
	background-color: rgba(63,141,191,0.5);
	cursor: pointer;
}
#gallery-header-center-left-title{
	float: left;
	height: 35px;
	font-size: 25px;
	color: #3f8dbf;
	margin-left: 20px;
}
#gallery-header-center-right{
	float: right;
	height: 35px;
	margin-top: 32px;
	line-height: 35px;
}
.gallery-header-center-right-links {
	color: #333333;
	float: left;
	height: 35px;
	padding-right: 20px;
	padding-left: 20px;
	margin-left: 10px;
	font-size: 16px;
	font-weight: 400;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
}
.gallery-header-center-right-links:hover {
	background-color:#0f9ad8;
	color: rgba(255,255,255,1);
	cursor: pointer;
}
.gallery-header-center-right-links-current {
	color: #FFFFFF;
	background-color:#0f9ad8;
}
.gallery-header-center-right-links-current:hover {
	background-color: rgba(63,141,191,0.5);
}
#gallery-content{
	float: left;
	width: 100%;
}
.gallery-content-center-normal {
	width: 950px;
	margin-right: auto;
	margin-left: auto;
}
.gallery-content-center-full {
	float: left;
	width: 100%;
}
#gallery-content-center img {
	width: 285px;
	margin: 10px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	border-radius: 10px;
	box-shadow: 0 10px 15px -5px rgba(62, 57, 107, .07);
}
