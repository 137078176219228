/*!
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.slider {
    display: block;
    vertical-align: middle;
    position: relative;

}
.slider.slider-horizontal {
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
}
.slider.slider-horizontal:last-of-type {
    margin-bottom: 0;
}
.slider.slider-horizontal .slider-track {
    height: 10px;
    width: 100%;
    margin-top: -5px;
    top: 50%;
    left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
    height: 100%;
    top: 0;
    bottom: 0;
}
.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
    margin-left: -10px;
    margin-top: -5px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
    border-width: 0 10px 10px 10px;
    width: 0;
    height: 0;
    border-bottom-color: #0480be;
    margin-top: 0;
}
.slider.slider-horizontal .slider-tick-label-container {
    white-space: nowrap;
    margin-top: 20px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    padding-top: 4px;
    display: inline-block;
    text-align: center;
}
.slider.slider-vertical {
    height: 430px;
    width: 20px;
    margin-right: 50px;
    display: inline-block;
}
.slider.slider-vertical .slider-track {
    width: 10px;
    height: 100%;
    margin-left: -5px;
    left: 50%;
    top: 0;
}
.slider.slider-vertical .slider-selection {
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
}
.slider.slider-vertical .slider-track-low,
.slider.slider-vertical .slider-track-high {
    width: 100%;
    left: 0;
    right: 0;
}
.slider.slider-vertical .slider-tick,
.slider.slider-vertical .slider-handle {
    margin-left: -5px;
    margin-top: -10px;
}
.slider.slider-vertical .slider-tick.triangle,
.slider.slider-vertical .slider-handle.triangle {
    border-width: 10px 0 10px 10px;
    width: 1px;
    height: 1px;
    border-left-color: #0480be;
    margin-left: 0;
}
.slider.slider-vertical .slider-tick-label-container {
    white-space: nowrap;
}
.slider.slider-vertical .slider-tick-label-container .slider-tick-label {
    padding-left: 4px;
}
.slider.slider-disabled .slider-handle {
    background-image: -webkit-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
    background-image: -o-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
    background-image: linear-gradient(to bottom, #dfdfdf 0%, #bebebe 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdfdfdf', endColorstr='#ffbebebe', GradientType=0);
}
.slider.slider-disabled .slider-track {
    background-image: -webkit-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
    background-image: -o-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
    background-image: linear-gradient(to bottom, #e5e5e5 0%, #e9e9e9 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5', endColorstr='#ffe9e9e9', GradientType=0);
    cursor: not-allowed;
}
.slider input {
    display: none;
}
.slider .tooltip.top {
  margin-top: -36px;
}
.slider .tooltip-inner {
    white-space: nowrap;
}
.slider .hide {
  display: none;
}
.slider-track {
    position: absolute;
    cursor: pointer;
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f0f0f0, #f9f9f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f0f0f0), to(#f9f9f9));
    background-image: -webkit-linear-gradient(top, #f0f0f0, #f9f9f9);
    background-image: -o-linear-gradient(top, #f0f0f0, #f9f9f9);
    background-image: linear-gradient(to bottom, #f0f0f0, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0f0f0', endColorstr='#fff9f9f9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}
.slider-selection {
    position: absolute;
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f9f9f9), to(#f5f5f5));
    background-image: -webkit-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: -o-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: linear-gradient(to bottom, #f9f9f9, #f5f5f5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.slider-selection.tick-slider-selection {
    background-image: -webkit-linear-gradient(top, #89cdef 0%, #81bfde 100%);
    background-image: -o-linear-gradient(top, #89cdef 0%, #81bfde 100%);
    background-image: linear-gradient(to bottom, #89cdef 0%, #81bfde 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
}
.slider-track-low,
.slider-track-high {
    position: absolute;
    background: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
}
.slider-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f7f7f7;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.5), 0 1px 4px rgba(0,0,0,.3);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.5), 0 1px 4px rgba(0,0,0,.3);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.5), 0 1px 4px rgba(0,0,0,.3);
    opacity: 1;
    border: 0px solid transparent;
	border-radius: 100px;
}
.slider-handle.round {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}
.slider-handle.triangle {
    background: transparent none;
}
.slider-handle.custom {
    background: transparent none;
}
.slider-handle.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
}
.slider-tick {
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
    background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    filter: none;
    opacity: 0.8;
    border: 0px solid transparent;
}
.slider-tick.round {
    border-radius: 50%;
}
.slider-tick.triangle {
    background: transparent none;
}
.slider-tick.custom {
    background: transparent none;
}
.slider-tick.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
}
.slider-tick.in-selection {
    background-image: -webkit-linear-gradient(top, #89cdef 0%, #81bfde 100%);
    background-image: -o-linear-gradient(top, #89cdef 0%, #81bfde 100%);
    background-image: linear-gradient(to bottom, #89cdef 0%, #81bfde 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
    opacity: 1;
}
.slider-disabled .slider-selection {
    opacity: 0.5;
}

#danger .slider-selection {
    background: #fc4b6c;
}

#info .slider-selection {
    background: #1e88e5;
}

#success .slider-selection {
    background: #26c6da;
}

#warning .slider-selection {
    background: #ffb22b;
}

#secondary .slider-selection {
    background: #616a78;
}

#primary .slider-selection {
    background: #0bb2d4;
}