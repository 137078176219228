.mainLogin{
    margin:auto;
    display: inline;
}


.form-group {
    display: flex;
    margin-bottom: 0!important;
}

.form-group input , .form-group span{
    border: 0;
    border-radius: 0;
    outline: none;
    padding: 10px 20px;
    font-size: 18px;
}

.mainLogin input{
    width: 100%;
}

.mainLogin button {
    width: 100%;
    padding: 10px 0!important;
    border: 0;
    border-radius: 5px;
    font-size: 18px;
    background-color: #019ff8;
    border-color: #019ff8;
    color: #ffffff;
}

.form-group span {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-color: #0f1421;
    color: #a1a4b5;
    background-color: #0f1421!important;
    width: 13%;
}


.form-group input{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #191d33;
    color: #a1a4b5;
    width: 87%;
}

.container {
    height: 80vh!important;
}

.error-message{
    color: red;
    padding: 10px 0;
}

.container {
    height: 100vh!important;

}

.loginPage{
    background: url(https://edulearn-lms-admin-template.multipurposethemes.com/images/auth-bg/bg-16.jpg);
}