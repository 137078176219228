/* http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript+ruby+css-extras+git+jade+json+php+php-extras+sass+scss+sql&plugins=line-highlight+line-numbers+show-invisibles+autolinker+file-highlight+show-language+jsonp-highlight+highlight-keywords+remove-initial-line-feed+autoloader+unescaped-markup+command-line+normalize-whitespace+keep-markup */


/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
    color: #8a8484;
    background: none;
    
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    font-size: 100%;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
	
	border:none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
    text-shadow: none;
    background: #b3d4fc;
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}




/* Code blocks */

pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    max-height: 250px;
    position: relative;
}

:not(pre)>code[class*="language-"],
pre[class*="language-"] {
    background: #FFF;
    border: 1px solid #F0F0F0;
    border-left: 2px solid #3BAFDA;
    border-radius: 4px;
}




/* Inline code */

:not(pre)>code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
    background: hsla(0, 0%, 100%, .5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07a;
}

.token.function {
    color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}

pre[data-line] {
    position: relative;
    padding: 1em 0 1em 3em;
}

pre[data-file]::before {
    content: attr(data-file);
    position: absolute;
    top: 0;
    right: 0;
}

.line-highlight {
    position: absolute;
    left: 0;
    right: 0;
    padding: inherit 0;
    margin-top: 1em;
    /* Same as .prism’s padding-top */
    background: hsla(24, 20%, 50%, .08);
    background: -moz-linear-gradient(to right, hsla(186, 83%, 68%, 0.11) 70%, hsla(186, 33%, 82%, 0));
    background: -webkit-linear-gradient(to right, hsla(186, 83%, 68%, 0.11) 70%, hsla(186, 33%, 82%, 0));
    background: -o-linear-gradient(to right, hsla(186, 83%, 68%, 0.11) 70%, hsla(186, 33%, 82%, 0));
    background: linear-gradient(to right, hsla(186, 83%, 68%, 0.11) 70%, hsla(186, 33%, 82%, 0));

    pointer-events: none;

    line-height: inherit;
    white-space: pre;
}

.line-highlight:before,
.line-highlight[data-end]:after {
    content: attr(data-start);
    position: absolute;
    top: .4em;
    left: .6em;
    min-width: 1em;
    padding: 0 .5em;
    color: #187798;
    font: 75%/1.5 sans-serif;
    text-align: center;
    vertical-align: .3em;
    border-radius: 999px;
    text-shadow: none;
    box-shadow: 0 1px white;
}

.line-highlight[data-end]:after {
    content: attr(data-end);
    top: auto;
    bottom: .4em;
}

pre.line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
}

pre.line-numbers>code {
    position: relative;
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em;
    /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 1px solid #999;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.line-numbers-rows>span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
}

.line-numbers-rows>span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: 0.8em;
    text-align: right;
}

.token.tab:not(:empty),
.token.cr,
.token.lf,
.token.space {
    position: relative;
}

.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before,
.token.space:before {
    color: hsl(24, 20%, 85%);
    position: absolute;
}

.token.tab:not(:empty):before {
    content: '\21E5';
}

.token.cr:before {
    content: '\240D';
}

.token.crlf:before {
    content: '\240D\240A';
}

.token.lf:before {
    content: '\240A';
}

.token.space:before {
    content: '\00B7';
}

.token a {
    color: inherit;
}

div.prism-show-language {
    position: relative;
}

div.prism-show-language>div.prism-show-language-label {
    color: #555;
    background-color: #E5E5E5;
    display: inline-block;
    position: absolute;
    bottom: auto;
    left: auto;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    font-size: 0.9em;
    border-radius: 0 4px 0 0;
    padding: 0 0.5em;
    text-shadow: none;
    z-index: 1;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}




/* Fallback, in case JS does not run, to ensure the code is at least visible */

.lang-markup script[type='text/plain'],
.language-markup script[type='text/plain'],
script[type='text/plain'].lang-markup,
script[type='text/plain'].language-markup {
    display: block;
    font: 100% Consolas, Monaco, monospace;
    white-space: pre;
    overflow: auto;
}

.command-line-prompt {
    border-right: 1px solid #999;
    display: block;
    float: left;
    font-size: 100%;
    letter-spacing: -1px;
    margin-right: 1em;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.command-line-prompt>span:before {
    color: #999;
    content: ' ';
    display: block;
    padding-right: 0.8em;
}

.command-line-prompt>span[data-user]:before {
    content: "[" attr(data-user) "@" attr(data-host) "] $";
}

.command-line-prompt>span[data-user="root"]:before {
    content: "[" attr(data-user) "@" attr(data-host) "] #";
}

.command-line-prompt>span[data-prompt]:before {
    content: attr(data-prompt);
}