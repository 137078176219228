p {
  color: white;
}

.kotta_div {
  padding-top: 50px;
  width: 95%;
  padding-left: 250px;
}

.student_map {
  width: 95%;
  padding-left: 250px;
  padding-top: 40px;
}

.student_card {
  width: 100%;
  height: 300px;
  border-radius: 30px;
  background: #212121;
  box-shadow: 5px 5px 10px rgb(25, 25, 25), -5px -5px 10px rgb(60, 60, 60);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Student Params */

.student_inputs {
  width: 90%;
  margin: auto;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 30px;
}

.student_params_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.student_inputs form {
  background-color: #293146;
  border-radius: 10px;
  padding: 20px;
}

.student_inputs input {
  width: 600px;
  height: 40px;
  border-radius: 30px;
  padding: 0.45rem 0.9rem;
  border-color: #191d33;
  background-color: #191d33;
  color: #a1a4b5;
  font-weight: 400;
  line-height: 1.5;
}

.students_group {
  padding-top: 40px;
}

.students_group h1 {
  text-align: center;
}

/* Assigned Groups */

.student_table1 {
  margin: auto;
  width: 90%;
  border-collapse: collapse;
  margin-top: 30px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

td {
  color: white;
}

/* Button */

.del-btn {
  background-color: #f04a4a !important;
}

.upd-btn {
  background-color: #191d33 !important;
}

.rainbow-hover {
  width: 200px;
  align-self: center;
  font-size: 16px;
  font-weight: 700;
  color: #ff7576;
  background-color: #2b3044;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  position: relative;
  line-height: 24px;
  border-radius: 9px;
  box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
  transform-style: preserve-3d;
  transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
  perspective: 600px;
  transition: transform 0.1s;
  margin-top: 25px;
}

.sp {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: block;
}

.rainbow-hover:active {
  transition: 0.3s;
  transform: scale(0.93);
}

/* Student Psot inputs */

.student_post_inputs {
  margin: auto;
  width: 95%;
  padding-left: 250px;
  padding-top: 40px;
}

.students_hehe {
  padding-top: 50px;
  width: 95%;
}

.hehe {
  margin: auto;
  width: 95%;
  padding-left: 250px;
  padding-top: 40px;
}

#st_btns {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  width: 100%;
}

input {
}

.kotta_div form {
  background-color: #293146;
  border-radius: 10px;
  padding: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#tr {
  width: 100%;
  font-size: 20px !important;
  height: 50px;
}

#td {
  display: flex;
  height: 100%;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_map {
  display: flex;
  flex-direction: column;
  padding-left: 500px;
}

#td2 {
  height: 100%;
  width: 500px;
  text-align: center;
}


#tr2{
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#td3{
  width: 100%;
}

.spec{
  font-size: 20px;
  font-weight: 800;
}