.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    z-index: 999; /* ensure it appears on top */
  }
  
  .popup-inner {
    background-color: #191d33;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #191d33;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 417px;
    height: 120px;
    position: relative; /* ensure child elements respect this context */
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .form-label{
    margin-right: 10px;
    font-family: monospace;
  }

.popup > .box {
    max-width: 50%;  
}